
<template>
    <div class="d-flex flex-column h-100" style="overflow: auto">
      <div class="d-flex flex-row mb-3 justify-content-center">
        
        <div class="d-flex flex-column justify-content-center" style="min-width: 20vw; max-width: 50vw; z-index: 0;">

          <div style="height: 1vh;"></div>
          
          <date-field
            :countDay="7"
            @selected-date="handleSelectionDate"
          ></date-field>

          <div style="height: 1vh;"></div>

          <base-input
            style="z-index: -1;"
            :labelName="$t('mlpage.min_coefficient')"
            v-model:value="min_coefficient"
            placeholder=""
            ref="coef"
            type="number"
            :step="0.1"
          ></base-input>

          <div style="height: 1vh;"></div>

          <base-input
          style="z-index: -1;"
            :labelName="$t('mlpage.min_quantity')"
            v-model:value="min_quantity"
            placeholder=""
            ref="quant"
            type="number"
            :step="1"
          ></base-input>

          <div style="height: 1vh;"></div>

          <base-multiselect
            v-if="conveniqs.length > 0"
            class="base-multiselect__item"
            style="z-index: -1;"
            :placeholder="$t('mlpage.select') + ' ' + $t('mlpage.conv')"
            :items="conveniqs"
            :value="sel_convenq"
            :multiple="false"
            :labelProp="'name'"
            :closeOnSelect="true"
            :search="true"
            @select="(payload) => {sel_convenq = payload;}"
          ></base-multiselect>
        </div>

        <div class="d-flex flex-column justify-content-start" style="min-width: 20vw; padding: 0px 10px; max-width: 50vw; z-index: 0;">
          <div style="height: 1vh;"></div>
          
          <div class="d-flex justify-content-start">
            <base-button
            style="z-index: unset;"
              :variant="'pure'"
              className="v-btn v-theme--ORANGE_THEME bg-success v-btn--density-default v-btn--size-default v-btn--variant-flat"
              @click="saveData"
            >
              <h2>{{$t('mlpage.run_exec')}}</h2>
            </base-button>
          </div>
        </div>
        
        
      </div>
      
      

      <spinner v-if="spinnerShow"></spinner>
    </div>
</template>


<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseMultiselect from "@/components/BaseMultiselect_new.vue";
import dateField from "@/components/dashboard/DateField.vue";
import EntityApi from "@/api/entityApi";
import BaseInput from "@/components/BaseInput.vue";

import {mapActions} from "pinia";
import {useDashboardStore} from "@/stores/dashbordStore";
import Spinner from "../Spinner.vue";

export default {
  name: "GetRecognizedPhoto-page",
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
    data: {
      type: Object,
    },
  },
  emits: ['closeModalf'],
  components: {
    BaseButton,
    BaseMultiselect,
    dateField,
    Spinner,
    BaseInput,
  },
  data() {
    return {
      spinnerShow: false,

      countSelectedDay: 0,
      dtstart: null,
      dtend: null,

      conveniqs: [],
      sel_convenq: null,

      min_coefficient: 0.7,
      min_quantity: 10
    };
  },
  methods: {
    ...mapActions(useDashboardStore, ['resetDate', 'setDate']),
    async getConveniqs(){
      this.spinnerShow = true;
      let res = await EntityApi.getEntityItems('conveniq');
      if(res && (res.length > 0)){
        this.conveniqs = res;
      } else {
        this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),text: this.$t('mlpage.errs.no_conv'),}, 3000);
        this.$emit('closeModalf', null);
      }
      this.spinnerShow = false;
    },
    saveData(){
      if(!this.dtend || !this.dtstart){
        this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),text: this.$t('mlpage.select') + ' ' + this.$t('mlpage.errs.date'),}, 3000);
        return
      }
      if(!this.sel_convenq && (this.conveniqs.length > 0)){
        this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),text: this.$t('mlpage.select') + ' ' + this.$t('mlpage.conv'),}, 3000);
        return
      }

      this.spinnerShow = true;
        EntityApi.sendCustomRequest(`/recognized-photos?start=${this.dtstart}&end=${this.dtend}&conveniq_id=${this.sel_convenq.id}&min_coefficient=${this.min_coefficient}&min_quantity=${this.min_quantity}`, 'get', null)
        .then(data=>{
          this.spinnerShow = false;
          console.log('sendCustomRequest', data);
          
          if(data){
            this.$notify({group: "alert",type:"info",title: this.$t('lables.success'),text: this.$t('mlpage.saved'),},3000);
            this.$emit('closeModalf', null);
            } else {
            this.$notify({
              group: "alert",
              type:"error",
              title: this.$t('lables.opps'),
              text: this.$t('lables.error'),
            }, 3000);
            
          }
        });


      // 
      // 
    },
    handleSelectionDate(payload){
      this.dtstart = payload.dateStart;
      this.dtend = payload.dateEnd;

      if(!payload.dateStart && !payload.dateEnd){
        this.resetDate(1);
      } else {
        this.setDate(payload.dateStart, payload.dateEnd);
      }
    },
  },
  provide() {
    return {
      updateModelValue: this.onDateChange,
      validateAllField: null,
      setError: null,
      clearValidationErrors:null,
      clearValidationErrorsForField: null,
    };
  },
  mounted() {
    this.getConveniqs();

    let currentDate = new Date();
    let weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    weekAgo.setHours(0, 0, 0, 0);
    this.dtstart = weekAgo.toISOString().split('T')[0] + 'T00:00:00';
    this.dtend = currentDate.toISOString().split('T')[0] + 'T23:59:59';
    this.setDate(new Date(this.dtstart), new Date(this.dtend));
  },
  beforeUnmount() {
  },
  sockets: {},
  computed: {
    
  },
  watch: {
    /*"modes.addingPoints": {
      handler(val) {
        
      },
      deep: true,
    },*/

  },
};
</script>
<style lang="scss" scoped>


.base-multiselect__value{
  border-color: #e2e8f0 !important;
  background-color: red;
}

.value__list{
  flex-wrap: wrap;
}

.border-choosen{
  border: 1px solid #000;
  border-radius: 4px;
  &:hover {
    border-color: #acacff;
    cursor: pointer;
  }
}
.remove{
  height: 1.5rem;
  width: 1.5rem;
}

</style>