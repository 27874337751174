<template>
  <svg-icon
    v-if="value === true"
    class="icon w-5 h-5 mdi-checkbox-marked mdi v-icon notranslate v-theme--BLUE_THEME text-24 text-primary"
    type="mdi"
    :path="mdiCheckboxMarked"
    :size="28"
  ></svg-icon>
   <svg-icon
    v-else
    class="icon w-5 h-5 mdi-checkbox-blank-outline mdi v-icon notranslate v-theme--BLUE_THEME text-24 text-grey"
    type="mdi"
    :path="mdiCheckboxBlankOutline"
    :size="35"
  ></svg-icon>
</template>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheckboxMarked , mdiCheckboxBlankOutline  } from "@mdi/js";
export default {
  name: "BoolRenderer",
  components: { SvgIcon },
  props: {},
  data() {
    return {
      value: null,
      mdiCheckboxMarked  : mdiCheckboxMarked ,
      mdiCheckboxBlankOutline :mdiCheckboxBlankOutline 
    };
  },
  methods: {},
  watch: {},
  computed: {},
  mounted() {},
  beforeMount() {
    if (this.params.data && this.params.data[this.params.column.colId]) {
      this.value = this.params.data[this.params.column.colId];
    }
  },
};
</script>

<style>
.text-grey {
  color: rgb(218, 221, 223);
}
</style>
