<template>
  <span>
    <img
      v-if="valueIsImage"
      class="item__img"
      :src="`${baseUrl}/${localValue}`"
      :alt="localValue"
    />
  </span>
</template>
<script>
const baseURL = process.env.VUE_APP_CDN_URL ? process.env.VUE_APP_CDN_URL : `${process.env.VUE_APP_API_URL}/uploads`;
export default {
  name: "FileRenderer",
  components: {},
  props: {},
  data() {
    return {
      localValue: null,
      baseUrl: baseURL,
    };
  },
  methods: {},
  watch: {},
  computed: {
    hasValue() {
      return this.localValue && this.localValue?.length > 0 ? true : false;
    },
    valueIsImage() {
      let imageType = (val) => {
        let fileExtension = val.split(".").pop();
        return ["png", "jpg", "bmp"].includes(fileExtension);
      };
      return this.hasValue && imageType(this.localValue) ? true : false;
    },
  },
  mounted() {},
  beforeMount() {
    this.params.value
      ? (this.localValue = this.params.value)
      : (this.localValue = "");
  },
};
</script>

<style>
.item__img {
  width: 45px;  
  object-fit: cover;
}
</style>
