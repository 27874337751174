<template>
  <div class="position-relative wraper tag-view">
    <span class="table-tag" :style="badgeStyle">
      {{ name }}
    </span>
  </div>
</template>
<script>
export default {
  name: "ObjectRenderer",
  components: {},
  props: {},
  data() {
    return {
      name: "",
      color: null,
    };
  },
  methods: {},
  watch: {},
  computed: {
    badgeStyle(){
        return this.name && this.color ? `background-color: ${this.color}`: '';
    }
  },
  mounted() {},
  beforeMount() {
     
    if(this.params.data && this.params.column.colId && this.params.data[this.params.column.colId]){
      if(this.params.colDef &&
        this.params.colDef.customTitleField &&
        this.params.data[this.params.column.colId][this.params.colDef.customTitleField]){
        this.name = this.params.data[this.params.column.colId][this.params.colDef.customTitleField];
      } else 
      if(this.params.data[this.params.column.colId].name){
        this.name = this.params.data[this.params.column.colId].name;
        this.color = this.params.data[this.params.column.colId].color;
      }
    }
    //console.log(this.params.data[this.params.column.colId].name);
  },
};
</script>

<style>
.tag-view{
  display:flex;
  align-items: center;
}
.tag-view .table-tag  {
    border-radius: 50rem !important;
    color: #000;
    /*display: inline-block;*/
    /*padding: 0.3em 0.5em;*/
    /*line-height: 1;*/
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;  
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
