import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { app } from "@/main.js";
// import createAuthRefreshInterceptor from "axios-auth-refresh";
const baseURL = process.env.VUE_APP_API_URL;
const axiosInstanсe = axios.create({ withCredentials: true, baseURL: baseURL });
async function refreshAccessToken() {
  let res = null;
  await axiosInstanсe
    .get(`${baseURL}/api/auth/refresh`, {}, { withCredentials: true })
    .then((refreshResponse) => {
      const userStore = useUserStore();
      userStore.setToken(refreshResponse.data.access_token);     
      res = refreshResponse.data.accessToken;
    })
    .catch(function (error) {
      const userStore = useUserStore();
      userStore.logout();
      if (error.response) {
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    });
  return res;
}

axiosInstanсe.interceptors.request.use(function (config) {
  const userStore = useUserStore();
  if (userStore.token && userStore.token.length > 0) {
    config.headers["access_token"] = userStore.token;
  }
  return config;
});
axiosInstanсe.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      originalRequest.headers["access_token"] = access_token;

      return axiosInstanсe(originalRequest);
    }
    return Promise.reject(error);
  }
);
export async function sendRequest(
  url,
  metods,
  req,
  headers = null,
  showLoader = false
) {
  if (showLoader) {
    app.config.globalProperties.$loaderSpiner.show = true;
  }
  let res = null;
  // axiosInstanсe.defaults.baseURL = baseURL;
  // axiosInstanсe.defaults.headers.common["Cache-Control"] = "no-cache";
  // axiosInstanсe.defaults.withCredentials = true;
  if (!headers) {
    axiosInstanсe.defaults.headers.post["Content-Type"] =
      "application/json; charset=utf-8";
  } else {
    axiosInstanсe.defaults.headers.put["Content-Type"] = headers;
  }

  try {
    await axiosInstanсe[metods](url, req, { interceptNetworkError: true }).then(
      (resp) => {
        if (showLoader) {
          app.config.globalProperties.$loaderSpiner.show = false;
        }
        res = resp.data;
      }
    );
  } catch (error) {
    console.error(error);
  }

  return res;
}
// axios.interceptors.response.use(undefined, function (error) {
//   if (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {

//         originalRequest._retry = true;
//         store.dispatch('LogOut')
//         return router.push('/login')
//     }
//   }
// })
