<template>
  <div class="d-flex w-100 flex-column justify-space-between" >
    <div class="d-flex w-100" style="position: relative;" @click="emitPhoto">
      <div style="position: absolute; top: 0px; left: 0px; width: 100%;" v-if="imageMarked && showMarked">
        <img :src="imageMarked" alt="no marked file" style="max-width: 100%; max-height: 100%;min-width: 100%;min-height: 100%;" />
      </div>
      <img :src="imageShlf" alt="no file" v-if="showFoto" style="max-width: 100%; max-height: 100%; pointer-events: none; height: fit-content;" />
      <!-- style="max-width: 100%; max-height: 100%;min-width: 100%;min-height: 100%;" -->
    </div>

    <div class="d-flex flex-column">
      <div class="d-flex flex-row w-100 justify-content-center">
        <h5>{{title}}</h5>
      </div>

      <div v-if="dataTable.length > 0"
        class="d-flex flex-column w-100 px-2"
        style=""
      >
        <div class="d-flex flex-column w-100" style="border-top: 1px solid rgba(0, 0, 0, 0.3);">
          <div
              class="d-flex flex-row w-100 row-hover" 
              v-for="row, index in dataTable" :key="index"
              :style="[row.attention ? 'background-color: rgba(255, 0, 0, 0.479)' : '']"
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.3);"
          >
            <div class="d-flex flex-column justify-content-center pl-2" style="flex: 1;">
              <span style="font-weight:200; font-size: 0.9rem; line-height: 1.1rem;">{{row.item_name}}</span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center" style="min-width: 20%; max-width: 30%;">
              <span style="font-weight:200; font-size: 0.9rem; line-height: 1.1rem;">{{row.titleVal}}</span>
            </div>
          </div>
        </div>
      </div>

      <h3 class="d-flex justify-content-center" v-else>{{$t('receipt_analyz.label.no_data_table')}}</h3>

    </div>
  </div>
</template>
<script>

export default {
  name: "item-analyz",
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {type: String, required: false, default: ''},
    showMarked: {type: Boolean, required: false, default: false},
  },
  emit: ['zoomPhoto'],
  inject: [],
  components: {
  },
  data() {
    return {
      showFoto: true,
    };
  },
  watch: {},
  computed: {
    imageShlf(){
      if(this.data && this.data.file_url){
        return this.data.file_url
      } else {
        return null
      }
    },
    imageMarked(){
      if(this.data && this.data.image_annotation){
        return 'data:image/png;base64,' + this.data.image_annotation
      } else {
        return null
      }
    },
    dataTable(){
      let arr = [];
      if(this.data && this.data.processed_data && (this.data.processed_data.length > 0)){
        arr = this.data.processed_data;
      }
      else if(this.data && this.data.remains && (this.data.remains.length > 0)){
        arr = this.data.remains;
      }

      arr.sort((a,b) => {
        if(a.sort_order && b.sort_order){
          return a.sort_order - b.sort_order
        } else {
          if (a.item_name < b.item_name) {
            return -1;
          } else if (a.item_name > b.item_name) {
            return 1;
          }
        }
      });

      return arr;
    },
  },
  methods: {
    emitPhoto(){
      this.$emit('zoomPhoto', null);
    },
  },
  created() {},
  mounted() {
  },
};
</script>

<style scoped>
.w-50{
  width: 50%;
}

.ag-horizontal-left-spacer {
  visibility: hidden;
}

.ag-body-horizontal-scroll{
  display: none !important;
}
.row-hover:hover{
  background-color: rgba(250, 137, 107, 0.6);
}
</style>
