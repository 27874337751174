
<template>
    <div class="d-flex flex-column h-100" style="overflow: auto">
      
      <div class="d-flex flex-column">

        <div class="d-flex flex-row justify-content-center mt-3 mb-3">
          <fieldFile 
            :textBtn="$t('advert.addFile')"
            :acceptTypes="'.png, .jpg, .jpeg, video/*'"
            :multiselect="true"
            @startUpload="spinnerShow = true;"
            @finishUpload="spinnerShow = false"
            @onError="onErr"
          ></fieldFile>
        </div>

        <div v-if="advert_files.length < 1" class="d-flex mb-3 mt-3 w-100 justify-content-center">
          <h2 class="d-flex justify-content-center" style="text-transform: none;">{{ $t('lables.noData') }}</h2>
        </div>
        
        <div v-if="advert_files.length > 0" class="d-flex flex-row v-card-text justify-content-center">

          <!-- :move="onMoveCallback" -->
          <draggable
            tag="div"
            v-model="advert_files"
            item-key="sorting_order"
            style="min-width: 45vw;"
            class="d-flex flex-column"
            v-bind="dragOptions"
            
            @change="change"
            @start="drag = true"
            @end="endDrag"
            handle=".entity-item"    
            :id="'file' + id"
            :sort="true"
          >
            <template #item="{ element }">
              <div
                class="d-flex w-100 flex-row align-items-center v-btn v-theme--ORANGE_THEME border-choosen entity-item"
                style="min-height: 45px; padding: 10px 5px; margin: 0px; margin-bottom: 0.5rem;"
              >
                <div class="d-flex flex-row w-100" style="justify-content: space-between;">
                  <div class="d-flex" style="flex: 1 0 auto;">
                    <h3 class="d-flex align-items-center" style="text-transform: none;">{{element.file}}</h3>
                  </div>

                  <base-input
                    v-if="checkImg(element.file)"
                    :labelName="$t('advert.timeout')"
                    v-model:value="element.timeout"
                    placeholder="enter timeout"
                    ref="tmtInput"
                    style="max-width: 7rem; margin: 0px 5px;"
                    type="number"
                    :step="1"
                  ></base-input>

                  <base-button
                    :variant="'pure'"
                    className="v-btn justify-content-around v-theme--ORANGE_THEME text-primary v-btn--density-default v-btn--size-default v-btn--variant-outlined"
                    @click.stop="removeFile(element)"
                  >
                    {{$t('advert.remFile')}}
                  </base-button>
                </div>
              </div>
              
            </template>
          </draggable>

        </div>
        <div class="d-flex justify-content-center mt-2">
          <base-button 
            :variant="'pure'"
            className="v-btn v-theme--ORANGE_THEME bg-success v-btn--density-default v-btn--size-default v-btn--variant-flat"
            @click="saveData()"
          >
            <h2>{{$t('buttons.saveChanges')}}</h2>
          </base-button>
        </div>

        
      </div>

      <spinner v-if="spinnerShow"></spinner>
    </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import fieldFile from "@/components/formGenerator/builder/fields/fieldFile.vue";
import EntityApi from "@/api/entityApi";
import Spinner from "../Spinner.vue";
import draggable from "vuedraggable";

export default {
  name: "advertizing-page",
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
    data: {
      type: Object,
    },
  },
  emits: ['closeModalf'],
  components: {
    BaseButton,
    BaseInput,
    Spinner,
    draggable,
    fieldFile,
  },
  data() {
    return {
      spinnerShow: false,

      advert_files: [],

      drag: false,
      img_res: ['.bmp','.gif','.jpg','.jpeg','.png'],

      not_saved: [],
    };
  },
  methods: {
    loadFileNames(){
      if(!this.id){
        this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),
          text: 'No id for data',
        }, 3000);
        return
      }
      
      this.spinnerShow = true;
      EntityApi.sendCustomRequest('/api/v1/conveniq/advertising?conveniq_id=' + this.id, 'get', null)
      .then(resp => {
        this.spinnerShow = false;
        if(resp.length > 0){
          this.advert_files = resp;
          this.advert_files.sort((a, b) => a.sorting_order - b.sorting_order);
          this.updateOrder();
        }
      });
      
    },
    saveData(){
      if(!this.id){
        this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),
          text: 'No id for data',
        }, 3000);
        return
      }

      EntityApi.sendCustomRequest('/api/v1/conveniq/update_advertising?conveniq_id=' + this.id, 'post', this.advert_files)
      .then(resp => {
        if(resp.length > 0){
          this.$notify({group: "alert",type:"info",title: this.$t('lables.success'),text: this.$t('mlpage.saved'),},3000);
          this.not_saved = [];
          this.$emit('closeModalf', null);
        } else {
          this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),text: this.$t('advert.files_upl'),}, 3000);
        }
      });

    },
    updateOrder(){
      let sortOrd = 0;

      this.advert_files.map((file)=>{
        file.sorting_order = sortOrd;
        if((!file.timeout) && this.checkImg(file.file)){
          file.timeout = 5;
        }
        ++sortOrd;
      });
      
    },
    change(evt) {
      if (evt.added) {
        let element = {...evt.added.element};
        console.log('adding', element)
      }      
      if (evt.removed) {         
        let element = {...evt.removed.element};
        console.log('removed', element)
      }
      if(evt.moved){
        let element = {...evt.moved.element};
        console.log('moved', element, evt.moved);

        this.updateOrder();
      }
    },
    /*onMoveCallback(evt) {
      //evt, originalEvent
      console.log('onMoveCallback',evt)
    },*/
    /*
    логіка з файлами
    1. при завантаженні файлу https://api.conveniq.net/upload-file/ - відповідь filename: "6fe8bcf5-ef4e-4c85-ab1a-3b645e1d37d3.jpg"

     */
    endDrag() {
      this.drag = false;
    },
    addFile(name){
      if(name){
        let ind = this.advert_files.length;
        this.advert_files.push({
          file: name,
          sorting_order: ind
        });
        this.updateOrder();
      }
    },
    removeFile(file){
      if(!file || !file.file){
        this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),
          text: 'No file to remove',
        }, 3000);
        return
      }
      
      EntityApi.sendCustomRequest('/delete-file?file_name=' + file.file, 'post', null)
      .then(() => {
        const idx = this.advert_files.findIndex(fileI => fileI.file == file.file);
        if(idx > -1){
          this.$notify({group: "alert",type:"info",title: this.$t('lables.success'),text: this.$t('advert.removed'),},3000);
          this.advert_files.splice(idx, 1);
        }
        
        this.saveData();
      });
    },
    onFileAdded(fieldName, newValue) {
      this.not_saved.push(newValue);
      this.addFile(newValue);
    },
    onErr(){
      this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),text: this.$t('advert.files_upl'),}, 3000);
    },
    checkImg(name){
      for (let substr of this.img_res) {
          if (name.includes(substr)) {
              return true;
          }
      }
      return false;
    },
    removeNotSaved(){
      this.not_saved.map(ff => {
        EntityApi.sendCustomRequest('/delete-file?file_name=' + ff, 'post', null)
        .then(() => {
          const idx = this.not_saved.findIndex(fileI => fileI == ff);
          if(idx > -1){
            this.not_saved.splice(idx, 1);
          }
        });
      });
    },
  },
  provide() {
    return {
      updateModelValue: this.onFileAdded,
      validateAllField: null,
      setError: null,
      clearValidationErrors: null,
      clearValidationErrorsForField: null,
    };
  },
  mounted() {
    this.loadFileNames();
  },
  beforeUnmount() {
    this.removeNotSaved();
  },
  sockets: {},
  computed: {
    dragOptions() {
      return {
        animation: "200",
        handle: ".entity-item",
        ghostClass: "ghost",
        group: "list-files",
      };
    },
  },
  watch: {
    /*"modes.addingPoints": {
      handler(val) {
        
      },
      deep: true,
    },*/

  },
};
</script>
<style lang="scss" scoped>
.entity-item {
  background-color: var(--sp-container-bg, #fff);
  border: var(--sp-border-width, 1px) solid #0000002b;
}
.entity-item:hover {
  cursor: pointer;
}
.ghost {
  background: repeating-linear-gradient(145deg,transparent,transparent 5px,#e8eaf1 5px,#e8eaf1 10px);
  z-index: 4;
}
.border-choosen{
  border: var(--bms-border-width, 1px) solid var(--bms-border-color, #000);
  border-radius: var(--bms-border-radius, 4px);
  &:hover {
    border-color: var(--bms-border-color-hover, #acacff);
  }
}
</style>