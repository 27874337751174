<template>
  <div class="d-flex flex-row">
    <span class="d-flex justify-content-center align-items-center">
      <div class="d-flex" v-if="value">
        <span
          class="rounded-div"
          :style="{
            backgroundColor:
              value.value == true
                ? 'rgba(100, 223, 2, 1)'
                : 'rgba(255, 32, 0, 1)',
          }"
        ></span>
        <span v-if="value.value == null">none</span>
      </div>

      <span class="d-flex ml-2" v-if="value.title">{{ value.title }}</span>
    </span>
  </div>
</template>
<script>
export default {
  name: "BoolRendererRounded",
  components: {},
  props: {},
  data() {
    return {
      value: null,
    };
  },
  methods: {},
  watch: {},
  computed: {},
  mounted() {},
  beforeMount() {
    if (this.params.data && this.params.data[this.params.column.colId]) {
      this.value = this.params.data[this.params.column.colId];
    }
  },

  /*{ backgroundColor: "rgba(100, 223, 2, 0.8)" }
                : { backgroundColor: "rgba(255, 32, 0, 0.65)" }
              : { backgroundColor: "rgba(119,119,119,0.7)" } */
};
</script>

<style>
.text-grey {
  color: rgb(218, 221, 223);
}
.rounded-div {
  width: 1.4rem;
  height: 1.4rem;
  background-color: lightblue;
  border: 1px solid #000000;
  border-radius: 100%; /* This creates a perfect circle */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
