<template>
  <nav
      class="v-navigation-drawer v-navigation-drawer--left v-navigation-drawer--expand-on-hover
      v-navigation-drawer--temporary v-navigation-drawer--active v-theme--ORANGE_THEME elevation-0 leftSidebar mobile-menu"
      left="" mobile-breakpoint="960" app=""
      style="left: 0px; z-index: 1008; transform: translateX(0%); position: fixed; height: calc((100% - 0px) - 0px); top: 0px; bottom: 0px; width: 256px;"
      ref="mobile_menu"
      :data-element="`mobile-menu`">
    <div class="v-navigation-drawer__content mobile-menu">
      <div class="pa-5 pl-4 mobile-menu">
        <div class="v-locale-provider v-locale--is-ltr mobile-menu">
          <div class="logo mobile-menu">
            <a href="/" class="">
              <img
                  src="@/assets/img/logo-dark.svg"
                  alt="Logo"
                  style="width: 190px"/>
            </a>
          </div>
        </div>
      </div>
      <div class="ps scrollnavbar ps--active-y mobile-menu">
        <div
            class="v-list v-theme--ORANGE_THEME bg-transparent v-list--density-default v-list--one-line py-6 px-4 navItem mobile-menu"
            tabindex="0"
            role="listbox">
          <header-mobile-menu-item
              :actionButton="'button-burger-menu'"
              v-for="item in menu"
              :key="item.id"
              :item="item"
              @click-outside="closeMobileMenu"
          >
          </header-mobile-menu-item>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>

import headerMobileMenuItem from "./HeaderMobileMenuItem.vue";
import {useMenuStore} from "@/stores/menuStore";
import {mapState, mapActions} from "pinia";

export default {
  name: "HeaderMobileMenu",
  emits: ["click-outside"],
  props: {
    actionButton: {
      type: String,
    },
  },
  components: {
    headerMobileMenuItem
  },
  data() {
    return {
      // menu:null
      isMobile: false,
      menuVisible: false
    };
  },
  computed: {
    ...mapState(useMenuStore, ["menu"]),
  },
  methods: {
    ...mapActions(useMenuStore, ["getUserMenu", "findMenuItemByProperty"]),

    hideSubMenu(e) {
      const areMobileMenu = this.hasMobileMenuAncestor(e.target);
      if (areMobileMenu) {
        return;
      }
      this.$emit("click-outside");
    },
    closeMobileMenu() {
      this.$emit("click-outside");
    },
    hasMobileMenuAncestor(element) {
      return element.closest('.mobile-menu') !== null;
    }
  },
  created() {
    this.getUserMenu();
  },
  mounted() {
    document.addEventListener("click", this.hideSubMenu);
  },
  unmounted() {
    document.removeEventListener("click", this.hideSubMenu);
  },
};
</script>
<style scoped>
.navItem {
  padding: 16px 0;
  margin: 0 auto;
  align-items: center;
  font-size: .875rem;
  position: relative;
  gap: 3px;
}
</style>