<template>
  <div class="wraper">
    <div class="menu-action-block">
      <span class="icon" @click.stop="showRowData">
        <svg-icon
          class="icon icon-tabler icon-tabler-pencil text-success"
          type="mdi"
          :path="mdiMagnify"
          :size="16"
        ></svg-icon>
      </span>
      <span class="icon" @click.stop="editRow" v-if="showEditBtn">
        <svg-icon
          class="icon icon-tabler icon-tabler-pencil text-warning"
          type="mdi"
          :path="mdiPencilOutline"
          :size="16"
        ></svg-icon>
      </span>
      <span class="icon" @click.stop="showDialog = true" v-if="showDeleteBtn">
        <svg-icon
          class="icon icon-tabler icon-tabler-trash text-error"
          type="mdi"
          :path="mdiTrashCanOutline"
          :size="16"
        ></svg-icon
      ></span>
    </div>
    <base-dialog v-model="showDialog" contentWidth="500px" :showOwerlay="true">
      <h2>{{ $t("lables.confirmDelete") }}</h2>
      <div class="d-flex justify-space-between mt-5">
        <base-button
          :variant="'pure'"
          className="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated"
          aria-haspopup="menu"
          aria-expanded="false"
          aria-owns="v-menu-669"
          @click="deleteRow"
        >
          {{ $t("buttons.confirm") }}
        </base-button>
        <base-button
          :variant="'pure'"
          className="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated "
          aria-haspopup="menu"
          aria-expanded="false"
          aria-owns="v-menu-669"
          @click="showDialog = false"
        >
          {{ $t("buttons.cansel") }}
        </base-button>
      </div>
    </base-dialog>
  </div>
</template>
<script>
import BaseDialog from "@/components/BaseDialog.vue";
import BaseButton from "@/components/BaseButton.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPencilOutline, mdiTrashCanOutline, mdiMagnify } from "@mdi/js";
export default {
  name: "RowMenuCellRenderer",
  mixins: [],
  components: { SvgIcon, BaseDialog, BaseButton },
  props: {},
  data() {
    return {
      text: "",
      link: "",
      colDef: null,
      mdiPencilOutline: mdiPencilOutline,
      mdiTrashCanOutline: mdiTrashCanOutline,
      mdiMagnify: mdiMagnify,
      showDialog: false,
      tableType: null,
    };
  },
  methods: {
    showRowData() {     
      this.params.context?.tabletype === "subTable"
        ? this.params.context?.instance?.onSubtableShowRowEvent(
            this.params.data
          )
        : this.params.context?.instance?.onShowRowData(this.params.data);
    },
    editRow() {
      this.params.context?.tabletype === "subTable"
        ? this.params.context?.instance?.onSubtableEditRowEvent(
            this.params.data
          )
        : this.params.context?.instance?.onEditRowEvent(this.params.data);
    },
    deleteRow() {
      this.params.context?.tabletype === "subTable"
        ? this.params.context?.instance?.onSubtableDeleteRowEvent(
            this.params.data
          )
        : this.params.context?.instance?.onDeleteRowEvent(this.params.data);
    },
  },
  watch: {},
  computed: {
    showEditBtn() {
      return this.params.context?.permission?.update && this.params.context?.instance?.cardType !== "show"
      // if () {
      //   return false;
      // } else return true;
    },
    showDeleteBtn() {
       return this.params.context?.permission?.delete && this.params.context?.instance?.cardType !== "show"
      // if (this.params.context?.instance?.cardType === "show") {
      //   return false;
      // } else return true;
    },
  },
  mounted() {
    // console.log(this.params.context);
  },
  beforeMount() {
    if (
      this.params.data &&
      this.params.data[this.params.column.colId] &&
      this.params.data[this.params.column.colId].link
    ) {
      this.link = this.params.data[this.params.column.colId].link;
    }
    this.params.value ? (this.text = this.params.value) : (this.text = "");
    this.params.colDef
      ? (this.colDef = this.params.colDef)
      : (this.colDef = null);
    if (this.params.columnApi?.tableType) {
      this.tableType = this.params.columnApi.tableType;
    }
  },
};
</script>

<style>
.menu-action-block {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  /* display: none; */
  padding-right: 3px;
}
.menu-action-block span {
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
}
.menu-action-block span:hover {
  color: #2750ae;
  background-color: #d0f0fd;
  border-radius: 50%;
}
.ag-row-hover .menu-action-block,
.ag-row-selected .action-block {
  display: block;
}
.link {
  width: 40px;
  height: 100%;
  color: rgb(61, 187, 209) !important;
}
.icon i {
  font-size: 14px;
}
.wraper {
  width: 100%;
  /*width: 100px;*/
  height: 100%;
}
.wraper:hover > .menu-action-block {
  display: block;
}
.wraper:hover > .link {
  opacity: 0.5;
}
.modal-dialogs {
  max-width: 600px;
}
</style>
