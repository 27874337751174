
<template>
    <div class="d-flex flex-column h-100" style="overflow: auto">
      <div class="d-flex flex-row mb-3 justify-content-center">
        <div class="d-flex flex-column justify-content-center" style="min-width: 20vw; max-width: 50vw;">
          <div style="height: 1vh;"></div>

          <base-input
            :labelName="$t('mlpage.login')"
            v-model:value="login"
            placeholder=""
            ref="login"
            type="text"
          ></base-input>

          <div style="height: 1vh;"></div>

          <base-input
            :labelName="$t('mlpage.password')"
            v-model:value="password"
            placeholder=""
            ref="passwrd"
            type="password"
          ></base-input>
        </div>
        
        
      </div>
      <div class="d-flex justify-content-center">
          <base-button 
            :variant="'pure'"
            className="v-btn v-theme--ORANGE_THEME bg-success v-btn--density-default v-btn--size-default v-btn--variant-flat"
            @click="saveData"
          >
            <h2>{{$t('mlpage.save')}}</h2>
          </base-button>
        </div>
      

      <spinner v-if="spinnerShow"></spinner>
    </div>
</template>


<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import EntityApi from "@/api/entityApi";
import Spinner from "../Spinner.vue";

export default {
  name: "MlServerCredentials-page",
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
    data: {
      type: Object,
    },
  },
  emits: ['closeModalf'],
  components: {
    BaseButton,
    BaseInput,
    Spinner
  },
  data() {
    return {
      spinnerShow: false,

      login: null,
      password: null,
      
    };
  },
  methods: {
    saveData(){
      if(!this.login){
        this.$notify({
          group: "alert",
          type:"error",
          title: this.$t('lables.error'),
          text: this.$t('mlpage.notfilled') + ' ' + this.$t('mlpage.login'),
        }, 3000);
      }
      if(!this.password){
        this.$notify({
          group: "alert",
          type:"error",
          title: this.$t('lables.error'),
          text: this.$t('mlpage.notfilled') + ' ' + this.$t('mlpage.password'),
        }, 3000);
      }

      if(this.login && this.password){
        this.spinnerShow = true;
        EntityApi.sendCustomRequest(`/ml/server-credentials/${this.id}`, 'post', {
          login: this.login,
          password: this.password
        })
        .then(data=>{
          this.spinnerShow = false;
          console.log('sendCustomRequest', data);
          
          if(data){
            this.$notify({
              group: "alert",
              type:"info",
              title: this.$t('lables.success'),
              text: this.$t('mlpage.saved'),
            },3000);
            //this.$emit('closeModalf', null);
          } else {
            this.$notify({
              group: "alert",
              type:"error",
              title: this.$t('lables.opps'),
              text: this.$t('lables.error'),
            }, 3000);
            
          }

        });
      } else {
        return
      }
    },
  },
  mounted() {
  },
  beforeUnmount() {
    
  },
  sockets: {},
  computed: {
    
  },
  watch: {
    /*"modes.addingPoints": {
      handler(val) {
        
      },
      deep: true,
    },*/

  },
};
</script>
<style lang="scss" scoped>

</style>