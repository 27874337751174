<template>
  <div class="d-flex flex-column w-100 analizator-container"
      style="position: relative;"
      ref="analizatorForm">
    <base-modal v-model="showPhotos" :showCloseBtn="true" :showOwerlay="true">
      <div class="d-flex w-100 platform-scroller"
        style="position: relative; height: calc(100vh - 50px);"
        
        ref="scrollableArea"
        id="zoomCont"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
        @mouseleave="handleMouseUp"
        @touchstart="touchmove"
        @touchmove="touchmove"
        @touchend="handleEnd"
      >
        <!-- :style="[areaWidth && areaHeight ? 'width: ' + areaWidth + 'px; height:' + areaHeight + 'px;' : '']" -->
        <div id="zoomEl" class="d-flex justify-content-center align-items-center w-100 h-100" v-if=" photosShelf[ind] && photosShelf[ind].data">
          <img v-if="photosShelf[ind].mask && showMarked" :src="photosShelf[ind].mask" style="position: absolute; display: flex; max-width: 100%; max-height: 100%; pointer-events: none; height: fit-content;"/>
          <img :src="photosShelf[ind].data" style="max-width: 100%; max-height: 100%; pointer-events: none; height: fit-content;"/>
        </div>

        <div style="position: absolute; top: 50%; right: 5px;" v-if="ind < (photosShelf.length - 1)">
          <button class="btn btn-secondary" @click="ind = ind + 1;">
            <i class="icon-left" style="color: rgb(24, 24, 24);"></i>
          </button>
        </div>
        <div style="position: absolute; top: 50%; left: 5px;" v-if="ind > 0">
          <button class="btn btn-secondary" @click="ind = ind - 1;">
            <i class="icon-right" style="color: rgb(0, 0, 0);"></i>
          </button>
        </div>
      </div>

      <div v-show="false">
        <input
            class="range"
            v-model="scale"
            type="range"
            :min="scaleMin"
            :max="scaleMax"
            :step="step"
        />
        <base-button
            style="margin-left: 5px"
            size="s"
            @click="zoomRes()"
            :disabled="scale === 1"
        >
          Reset
        </base-button>
      </div>
    </base-modal>

    <div class="d-flex flex-row w-100 justify-content-between mb-3" style="border-bottom: 1px solid #e2e8f0"
         v-if="!nodata && !spinnerShow">
      <base-button
          className="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated mt-2"
          @click="getMPhotos()"
      >
        {{titleBtnZones}}
      </base-button>
      <div class="d-flex flex-column ">
        <h4 class="d-flex justify-content-center">{{$t('receipt_analyz.label.disp_data')}}:</h4>
        <div class="d-flex flex-row align-items-center">
          <base-checkbox
            v-for="check, index in checks" :key="index"
            :text="$t(check.title)"
            :id="check.id"
            :value="check.show"
            :modelValue="check.show"
            @update:modelValue="check.show = $event"
            :trueValue="true"
            :falseValue="false"
          ></base-checkbox>
        </div>

      </div>
    </div>
    <div class="d-flex flex-column w-100 justify-content-between" v-for="shelf, index in shelves" :key="index">

      <div class="d-flex w-100">
        <h2 class="text-center w-100">{{ shelf.shelf_name }}</h2>
      </div>
      <div class="d-flex flex-row w-100 mb-2">
        <div class="d-flex flex-row m-0 p-0">

          <div 
            v-if="shelf.previous_operation && checks.previous_operation && checks.previous_operation.show"
            class="d-flex flex-column m-0 p-0"
            :class="[ shelf.itemsLen ? ('v-col-md-' + (12 / shelf.itemsLen)) : '']"
          >
            <itm-analyz
              :data="shelf.previous_operation"
              :title="$t('receipt_analyz.label.previous_operation') + ' ' + shelf.previous_operation.document + ' ' + dtFormat(shelf.previous_operation.date)"
              :showMarked="showMarked"
              @zoomPhoto="() => { zoomPhoto(shelf, checks.previous_operation) }">
            </itm-analyz>
          </div>

          <div
            v-if="shelf.before && checks.before && checks.before.show"
            class="d-flex flex-column m-0 p-0 justify-end"
            :class="[ shelf.itemsLen ? ('v-col-md-' + (12 / shelf.itemsLen)) : '']"
          >
            <itm-analyz 
              :data="shelf.before"
              :title="$t('receipt_analyz.label.before')"
              :showMarked="showMarked"
              @zoomPhoto="() => { zoomPhoto(shelf, checks.before) }">
            </itm-analyz>
          </div>

          <div
            v-if="shelf.after && checks.after && checks.after.show"
            class="d-flex flex-column m-0 p-0 justify-end"
            :class="[ shelf.itemsLen ? ('v-col-md-' + (12 / shelf.itemsLen)) : '']"
          >
            <itm-analyz
              :data="shelf.after"
              :title="$t('receipt_analyz.label.after')"
              :showMarked="showMarked"
              @zoomPhoto="() => { zoomPhoto(shelf, checks.after) }">
            </itm-analyz>
          </div>
        </div>
      </div>
    </div>
    <div v-if="nodata && !spinnerShow" style="display: flex; flex: 1 0 auto; align-items: center;">
      <h2 class="text-center w-100">{{$t('receipt_analyz.label.no_data')}}</h2>
    </div>
    <spinner v-if="spinnerShow"></spinner>
  </div>
</template>
<script>
import {sendRequest} from "@/helpers";
import itmAnalyz from "@/components/instanceModules/itm-receipt.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import Spinner from "../Spinner.vue";

export default {
  name: "Analyzator",
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  inject: [],
  components: {
    itmAnalyz,
    BaseButton,
    BaseModal,
    BaseCheckbox,
    Spinner,
  },
  data() {
    return {
      shelves: [],
      nodata: true,
      showMarked: true,

      spinnerShow: true,

      showPhotos: false,
      photosShelf: [],
      ind: 0,

      checks: {
        /*previous_operation: {id: 0, title: 'Previous', show: true, field: 'previous'},
        before: {id: 1, title: 'Before door open', show: true, field: 'before'},
        after: {id: 2, title: 'After recognition', show: true, field: 'after'},*/
      },


      scale: 1,
      step: 0.1,
      scaleMin: 0.99,
      scaleMax: 5,
      areaWidth: 0,
      areaHeight: 0,
      isMouseDown: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,

      //touch
      lastPinch: 0,
      stepTouch: 0.01,
    };
  },
  watch: {
    scale(newVal) {
      let el = document.getElementById("zoomEl");
      if (el) {
        el.style.transform =
            el.style.WebkitTransform =
                el.style.MsTransform =
                    "scale(" + newVal + ")";
        el.style.transformOrigin = "0 0";
      }

    },
  },
  computed: {
    titleBtnZones(){
      let text = '';
      if(this.showMarked){
        text = this.$t('receipt_analyz.btn.hide');
      } else {
        text = this.$t('receipt_analyz.btn.show');
      }
      text = text + ' ' + this.$t('receipt_analyz.btn.mark_photo');
      return text;
    },
  },
  methods: {
    checkDataItem(elem){
      let item = elem;
      if(!elem){
        item = {
          item_id: "----------",
          item_name: "missing element",
          quantity: 0,
          attention: false,
        }
      } else {
        item.item_id = elem.item_id ? elem.item_id : "----------",
        item.item_name = elem.item_name ? elem.item_name : "missing element",
        item.quantity = elem.quantity ? elem.quantity : 0;
        item.attention = false;
      }

      return item;
    },
    getData() {
      let self = this;
      let ent = null;
      if(this.$route.params){
        if(this.$route.params.entity){
          ent = this.$route.params.entity;
        } else 
        if(this.$route.params.entitys){
          ent = this.$route.params.entitys;
        }
      }

      let url = `/api/document_items_data?instance_id=${this.id}`;
      if(ent){ url = url + `&entity=${ent}`; }

      sendRequest( url, "get", null)
          .then(resp => {
            if (resp && resp.length > 0) {
              let data = resp;

              this.checks = {};
              //this.shelves = [];

              let el_befor = null, el_curr = null, indx = -1;

              let weightShelf = false;

              let countOperations = 0;
              let KeysRow = [], allKeys = [];

              for (let i = 0; i < data.length; i++) {
                countOperations = 0;
                KeysRow = [];
                weightShelf = false;

                if(data[i].identify_type){
                  data[i].shelf_name = data[i].shelf_name + ' (' + data[i].identify_type + ')';
                  if(data[i].identify_type == 'weight'){
                    weightShelf = true;
                  }
                }

                Object.keys(data[i]).forEach((key) => {
                  if( (typeof (data[i][key]) == 'object') && Array.isArray(data[i][key].processed_data)){
                    KeysRow.push(key);
                    if(!allKeys.includes(key)){ allKeys.push(key); }
                  }
                }, data[i]);

                data[i].itemsLen = KeysRow.length;

                if(KeysRow.length > 1){
                  if(countOperations < KeysRow.length){ countOperations = KeysRow.length; }

                  //for first type operation
                  let el = null;
                  for (let j = 0; j < data[i][KeysRow[0]].processed_data.length; j++) {
                    el = null;
                    data[i][KeysRow[0]].processed_data[j] = this.checkDataItem(data[i][KeysRow[0]].processed_data[j]);
                    el = data[i][KeysRow[0]].processed_data[j];

                    el.titleVal = el.quantity;
                    if(el.weight != undefined && weightShelf){
                      el.titleVal = el.quantity + ' (' + el.weight + self.$t('receipt_analyz.label.gram') + ')';
                    }
                    if(el.remains){
                      el.titleVal = el.titleVal + ' / ' + el.remains + ' ' + self.$t('receipt_analyz.label.itm_remain');
                    }
                    data[i][KeysRow[0]].processed_data[j] = el;
                  }

                  for (let a = 1; a < KeysRow.length; a++) {
                    if(data[i][KeysRow[0]].processed_data && (data[i][KeysRow[a]].processed_data.length > 0)){
                      
                      //compare quantities
                      for (let j = 0; j < data[i][KeysRow[a]].processed_data.length; j++) {
                        
                        data[i][KeysRow[0]].processed_data[j] = this.checkDataItem(data[i][KeysRow[0]].processed_data[j]);
                        el_befor = data[i][KeysRow[0]].processed_data[j];
                        data[i][KeysRow[a]].processed_data[j] = this.checkDataItem(data[i][KeysRow[a]].processed_data[j]);
                        el_curr = data[i][KeysRow[a]].processed_data[j];
                        data[i][KeysRow[a]].processed_data[j].titleVal = el_curr.quantity;
                        
                        indx = -1;
                        indx = data[i][KeysRow[a]].processed_data.findIndex(el => {return el.item_id == el_befor.item_id});
                        if((indx > -1) && (el_befor.quantity != data[i][KeysRow[a]].processed_data[indx].quantity)){
                          data[i][KeysRow[0]].processed_data[j].attention = true;
                          data[i][KeysRow[a]].processed_data[indx].attention = true;
                        }

                        //write weight near quantity
                        if(el_curr.weight != undefined && weightShelf){
                          data[i][KeysRow[a]].processed_data[j].titleVal = data[i][KeysRow[a]].processed_data[j].titleVal + ' (' + el_curr.weight + this.$t('receipt_analyz.label.gram') + ')';
                        }

                        if(el_curr.remains){
                          data[i][KeysRow[a]].processed_data[j].titleVal = data[i][KeysRow[a]].processed_data[j].titleVal + ' / ' + el_curr.remains + ' ' + this.$t('receipt_analyz.label.itm_remain');
                        }
                      }

                      //sorting after comparing
                      (data[i][KeysRow[a]].processed_data).sort((a,b) => {
                        if(a.sort_order && b.sort_order){
                          return a.sort_order - b.sort_order
                        } else {
                          if (a.item_name < b.item_name) {
                            return -1;
                          } else if (a.item_name > b.item_name) {
                            return 1;
                          }
                        }
                      });
                    }
                  }
                }
              }

              //remove not existing operations
              let id = null;
              for (let i = 0; i < allKeys.length; i++) {
                switch (allKeys[i]) {
                  case 'after':
                    id = allKeys.length - 1;
                    break;
                  case 'previous_operation':
                    id = 0;
                    break;
                  default:
                    id = 1;
                    break;
                }
                this.checks[allKeys[i]] = {
                  id: id,
                  title: 'receipt_analyz.label.' + allKeys[i],
                  show: true,
                  field: allKeys[i]
                };
              }

              this.shelves = data;
            }

            if (this.shelves.length < 1) {
              this.nodata = true;
            } else {
              this.nodata = false;
            }
            this.spinnerShow = false;
          });
    },
    getMPhotos() {
      this.showMarked = !this.showMarked;
    },
    zoomPhoto(shelf, objVis) {
      this.scale = 1;
      this.photosShelf = [];
      this.ind = objVis.id;

      if (shelf.previous_operation && this.checks.previous_operation && this.checks.previous_operation.show) {
        this.photosShelf.push({
          data: shelf.previous_operation.file_url,
          mask: shelf.previous_operation.image_annotation? ('data:image/png;base64,' + shelf.previous_operation.image_annotation) : null
        });
      }

      if (shelf.before && this.checks.before && this.checks.before.show) {
        this.photosShelf.push({data: shelf.before.file_url});
      }

      if (shelf.after && this.checks.after && this.checks.after.show) {
        this.photosShelf.push({
          data: shelf.after.file_url,
          mask: shelf.after.image_annotation ? ('data:image/png;base64,' + shelf.after.image_annotation) : null
        });
      }

      this.showPhotos = true;

      this.$nextTick(() => {
        this.calculateAreaSize();
        this.detectCtrlWheel();
        window.addEventListener("resize", this.calculateAreaSize);
      });
    },

    detectCtrlWheel() {
      let el = document.getElementById("zoomCont");
      let this2 = this;

      el.addEventListener("mousewheel", function (e) {
        if (e.ctrlKey) {
          var delta = e.deltaY || e.detail || e.wheelDelta;

          if (delta > 0) {
            if (this2.scale > this2.scaleMin)
              this2.scale = this2.scale - this2.step;
          } else {
            if (this2.scale < this2.scaleMax)
              this2.scale = this2.scale + this2.step;
          }

          // отменим прокрутку
          e.preventDefault();
        }
      });
    },
    calculateAreaSize() {
      const scrollableArea = this.$refs.scrollableArea;
      this.areaWidth = scrollableArea.offsetWidth;
      this.areaHeight = scrollableArea.offsetHeight;
    },
    zoomRes() {
      this.scale = 1;
    },
    handleMouseDown(e) {
      this.isMouseDown = true;
      this.startX = e.clientX;
      this.startY = e.clientY;
      this.scrollLeft = this.$refs.scrollableArea.scrollLeft;
      this.scrollTop = this.$refs.scrollableArea.scrollTop;
      this.$refs.scrollableArea.style.cursor = "grabbing";
    },
    handleMouseMove(e) {
      if (!this.isMouseDown) return;
      const x = e.clientX;
      const y = e.clientY;
      const deltaX = x - this.startX;
      const deltaY = y - this.startY;
      this.$refs.scrollableArea.scrollLeft = this.scrollLeft - deltaX;
      this.$refs.scrollableArea.scrollTop = this.scrollTop - deltaY;
    },
    handleMouseUp() {
      this.isMouseDown = false;
      this.$refs.scrollableArea.style.cursor = "grab";

      this.lastPinch = 0;
    },
    touchmove(event){
      if (event.touches && event.touches.length > 1) {
        this.zoomPinch(event);
        return;
      }
    },
    zoomPinch(event) {

      let curDiff = Math.abs(event.touches[0].clientX - event.touches[1].clientX);

      if (this.lastPinch) {
          event.deltaY = this.lastPinch - curDiff;
          
          let delta = event.deltaY;
          if (delta > 0) {
              if (this.scale > this.scaleMin)
              this.scale = this.scale - this.stepTouch;
          } else {
              if (this.scale < this.scaleMax)
              this.scale = this.scale + this.stepTouch;
          }

          event.preventDefault();
      }
      this.lastPinch = curDiff;
    },
    handleEnd(event) {
        if (event.button && event.button !== 0) {
            return;
        }
        this.lastPinch = 0;
    },
    convertDateForIos(date) {
      if (typeof date == 'string') {
        let arr = date.split(/[- :]/);
        date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        return date;
      } else {
        return date
      }
    },
    dtFormat(dt) {

      if ((dt != '') && (dt != null)) {
        let d = null;

        if (typeof dt == 'string') {

          if (dt.indexOf('T') > -1) {
            let arr = (dt).split('T');
            let dataT = arr[0] + ' ' + arr[1];
            d = this.convertDateForIos(dataT);
          } else {
            d = this.convertDateForIos(dt);
          }

          let dtn = +this.convertDateForIos(d)
          if (dtn < 0) {
            return null
          }
        } else if (typeof dt == 'number') {
          d = new Date(dt);
        } else {
          d = new Date(dt);
        }

        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let hours = d.getHours();
        let min = d.getMinutes();
        let sec = d.getSeconds();
        if (day < 10) {
          day = '0' + day
        }
        if (month < 10) {
          month = '0' + month
        }
        if (hours < 10) {
          hours = '0' + hours
        }
        if (min < 10) {
          min = '0' + min
        }
        if (sec < 10) {
          sec = '0' + sec
        }

        if ((hours == '00') && (min == '00')) {
          return day + '.' + month + '.' + year;
        }

        return day + '.' + month + '.' + year + ' ' + hours + ':' + min + ':' + sec
      } else {
        return dt;
      }
    },
  },
  created() {
  },
  mounted() {
    this.getData();
    this.$emit("getFormHeight", 2000);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calculateAreaSize);
  },
};
</script>
<style scoped>
.w-50 {
  width: 50%;
}

.analizator-container {
  min-height: 600px;
}

.btn-secondary {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #303030;
}

.icon-left:before {
  content: "\02C3";
}

.icon-right:before {
  content: "\02C2";
}

.platform-scroller {
  z-index: 1;
  flex-grow: 1;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  margin-right: 0px;
  margin-bottom: 0px;
}

.tabs-content {
  overflow: auto;
}
</style>
