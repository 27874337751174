<template>
  <span>
    {{ formateadDay }}
  </span>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "DateRenderer",
  components: {},
  props: {},
  data() {
    return {
      value: null,
    };
  },
  methods: {},
  watch: {},
  computed: {
    formateadDay() {
      return this.value
        ? dayjs(this.value).format("DD.MM.YY HH:mm:ss")
        : "";
    },
  },
  mounted() {},
  beforeMount() {
    if (this.params.data && this.params.data[this.params.column.colId]) {
      this.value = this.params.data[this.params.column.colId];  
    }
  },
};
</script>

<style></style>
