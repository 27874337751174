// export function debounce(func, timeout = 300) {
//   let timer;
//   return (...args) => {
//     if (!timer) {
//       func.apply(this, args);
//     }
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       timer = undefined;
//     }, timeout);
//   };
// }

export function debounce(func, wait = 300, immediate = false) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
export const isObject = (obj) => {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
};
export const hasProperty = (obj, prop) => {
  if(obj){
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }
  else return null
};
export function groupBy(data, property) {
  return data.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}
export function getMonthName(monthNum) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months[monthNum - 1];
}