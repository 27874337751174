<template>
  <ul class="gap-1 horizontal-navbar mx-lg-0 mx-1"
      v-if="menu && !isMobile">
    <header-menu-item
        v-for="item in menu"
        :key="item.id"
        :item="item"
    ></header-menu-item>
  </ul>
  <button v-if="isMobile"
          type="button"
          class="v-btn v-btn--icon v-theme--ORANGE_THEME v-btn--density-default v-btn--size-small v-btn--variant-text hidden-lg-and-up button-burger-menu"
          @click.stop="showMobileMenu()">
    <span class="v-btn__overlay"></span>
    <span class="v-btn__underlay"></span>
    <span class="v-btn__content" data-no-activator="">
      <svg xmlns="http://www.w3.org/2000/svg"
           class="icon icon-tabler icon-tabler-menu-2"
           width="25px"
           height="25px"
           viewBox="0 0 24 24"
           stroke-width="2"
           stroke="currentColor"
           fill="none"
           stroke-linecap="round"
           stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <line x1="4" y1="6" x2="20" y2="6"></line>
        <line x1="4" y1="12" x2="20" y2="12"></line>
        <line x1="4" y1="18" x2="20" y2="18"></line>
      </svg>
    </span>
  </button>
</template>
<script>

import HeaderMenuItem from "./HeaderMenuItem.vue";
import { useMenuStore } from "@/stores/menuStore";
import { mapState, mapActions } from "pinia";

export default {
  name: "HeaderMenu",
  props: {},
  components: {
    HeaderMenuItem
  },
  emits:["showMobileMenu"],
  data() {
    return {
      // menu:null
      isMobile: false,
      menuVisible: false,
      state: false
    };
  },
  computed: {
    ...mapState(useMenuStore, ["menu"]),
  },
  methods: {
    ...mapActions(useMenuStore, ["getUserMenu", "findMenuItemByProperty"]),
    showMobileMenu() {
      this.$emit("showMobileMenu");
    },
  },
  created() {
    this.getUserMenu();
  },
  mounted() {
    this.isMobile = window.innerWidth <= 980;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 980;
    });
  },
};
</script>
<style scoped>
.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}

.menu-items li {
  list-style: none;
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.menu-items a {
  text-decoration: none;
  color: #444;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.menu-items a:hover {
  color: #117964;
  transition: color 0.3s ease-in-out;
}

</style>